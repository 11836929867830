/*jshint jquery: true, devel: true */
/*global Modernizr: true, acs: true, _: true , Bloodhound:true*/
/**
 * TODO -
 * 1) parameterize the curated URL
 * 2) parameterize 'maybe you meant to seach in' links
 * 3) convert to react js
 */
import './acs-typeahead.bundle.js';
import './acs-sayt-templates.js';

export function search() {
  if (typeof acs === 'undefined') {
    window.acs = {};
  }

  // initialize variables
  var $searchinput;
  var searchforms;
  var $typeaheadmenu;
  if ($('#searchbar').length) {
    $searchinput = $('#searchsite-results');
    searchforms = document.querySelectorAll('#searchbar form');
  } else {
    $searchinput = $('#searchsite');
    searchforms = document.querySelectorAll('#search form');
  }

  // this is a hack until we set up a proper acs.XX namespace thing for the rootdomain
  var _getRootDomain2 = function () {
    if (acs && acs.sso && acs.sso.loginUrl && acs.sso.loginUrl.indexOf('/bin/acs/sso/login') > -1) {
      return acs.sso.loginUrl
        .replace('/bin/acs/sso/login', '')
        .replace('https:', 'http:')
        .replace('http:', window.location.protocol)
        .replace('cmswwwtst', 'cmswwwdev');
    }
    return 'https://www.acs.org';
  };
  acs.search = acs.search || {};
  acs.search.configs = acs.search.configs || {};
  acs.search.configs.sayt = acs.search.configs.sayt || {};
  acs.search.configs.sayt.responderUrl = acs.search.configs.sayt.responderUrl
    ? acs.search.configs.sayt.responderUrl
    : 'https://search.acs.org/content/search/acs/en/search.sayt.json';
  acs.search.configs.sayt.curatedUrl = acs.search.configs.sayt.curatedUrl
    ? acs.search.configs.sayt.curatedUrl
    : '/etc/designs/acs/curated/acs/_jcr_content.json';
  acs.search.configs.sayt.maxFeaturedResults = acs.search.configs.sayt.maxFeaturedResults ? acs.search.configs.sayt.maxFeaturedResults : 1;
  acs.search.configs.sayt.maxGSAResults = acs.search.configs.sayt.maxGSAResults ? acs.search.configs.sayt.maxGSAResults : 5;
  acs.search.configs.sayt.maxWsoResults = acs.search.configs.sayt.maxWsoResults ? acs.search.configs.sayt.maxWsoResults : 5;
  acs.search.configs.sayt.maxTotalResults = acs.search.configs.sayt.maxTotalResults ? acs.search.configs.sayt.maxTotalResults : 7;
  acs.search.configs.sayt.saytOrder = acs.search.configs.sayt.saytOrder ? acs.search.configs.sayt.saytOrder : 1;
  acs.search.goMobile =
    acs.search.goMobile ||
    function () {
      // handles all logic switching to mobile display
      if ($typeaheadmenu) {
        $typeaheadmenu.hide();
      }
      searchforms.forEach(searchform => {
        searchform.style.width = 'auto'; // set width to auto for mobile size
      });
    };
  acs.search.goFullSize =
    acs.search.goFullSize ||
    function () {
      // handles all logic after switching to full size display
      if ($typeaheadmenu && $searchinput.val()) {
        $typeaheadmenu.show();
      }
    };
  acs.search.init =
    acs.search.init ||
    function () {
      // Handle window resizing
      if (window.matchMedia('(max-width:767px)').matches) {
        acs.search.goMobile();
      } else {
        acs.search.goFullSize();
      }
      window.matchMedia('(max-width:767px)').addEventListener('change', function (event) {
        if (event.matches) {
          acs.search.goMobile();
        } else {
          acs.search.goFullSize();
        }
      });

      // Handle placeholder attribute for old browsers
      $searchinput.attr('placeholder', 'Search');
      if (acs.search.configs.sayt && acs.search.configs.sayt.responderUrl) {
        this.initTypeahead();
      }

      if ($('.clear-searchbar').length && this.initInputClear) {
        this.initInputClear();
      }
    };

  acs.search.initInputClear = function () {
    $('.clear-searchbar').on('click', function () {
      $searchinput.val('');
    });
  };

  acs.search.initTypeahead =
    acs.search.initTypeahead ||
    function () {
      // remove default autocomplete behavior
      $searchinput.attr('autocomplete', 'off');

      var options = acs.search.configs.sayt;
      var acsBloodhoundConfig = {
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: options.responderUrl,
          prepare: function (query, settings) {
            settings.dataType = 'jsonp';
            settings.url = options.responderUrl;
            settings.data = {
              query: query.toLowerCase(),
              jsonp: 'searchAsYouType.handleAjaxResponse',
              maxGSAResults: options.maxGSAResults,
              maxWsoResults: options.maxWsoResults,
              saytOrder: options.saytOrder, // WSO entries will display at the top
            };
            return settings;
          },
          filter: function (data) {
            return data.results;
          },
        },
      };

      // basic GSA search bloodhound
      var acsBloodhound = new Bloodhound(acsBloodhoundConfig);
      acsBloodhound.initialize();

      // featured bloodhound from CQ
      var featuredBloodhoundConfig = {
        datumTokenizer: function (o) {
          // we want to use the tags from the servlet verbatim
          return o.tags;
        },
        queryTokenizer: function (o) {
          // we want to use the entire chunk of text, not split by anything
          return Array.isArray(o) ? o : [o];
        },
        identify: function (_obj) {
          return _obj.path;
        },
        sorter: function (a, b) {
          // show newer curated pieces of content on top
          var d1 = new Date(a.dateLastModified).getTime();
          var d2 = new Date(b.dateLastModified).getTime();
          return (d1 < d2) - (d1 > d2);
        },
        prefetch: {
          url: _getRootDomain2() + acs.search.configs.sayt.curatedUrl,
          cache: true,
          ttl: 86400000, // 1 day
          filter: function (response) {
            // apparently acs.page isnt available on search test
            if (acs && acs.page && acs.page.path) {
              var currentPagePath = acs.page.path + '.html';
              return response.results.filter(o => o.path !== currentPagePath);
            } else {
              return response.results;
            }
          },
        },
      };
      //var featuredBloodhound = new Bloodhound(featuredBloodhoundConfig);
      //featuredBloodhound.initialize();

      $searchinput.typeahead(
        {
          hint: true,
          highlight: true,
          minLength: 2,
        },
        /*        {
          limit: options.maxFeaturedResults,
          displayKey: 'title',
          source: featuredBloodhound,
          templates: acs.ui.sayt.featuredTemplates,
        },*/
        {
          limit: options.maxTotalResults,
          displayKey: 'name',
          async: true,
          source: acsBloodhound,
          templates: acs.ui.sayt.gsaTemplates,
        },
      );
    };
}
search();
