/* ============================================================
 * bootstrap-dropdown.js v2.3.2
 * http://twitter.github.com/bootstrap/javascript.html#dropdowns
 * ============================================================
 * Copyright 2012 Twitter, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ============================================================ */

export function bootstrapDropdown() {
  class Dropdown {
    constructor(element) {
      this.el = element;
      this.handleDocumentClick = this.handleDocumentClick.bind(this);
      this.handleKeydown = this.handleKeydown.bind(this);
      this.toggle = this.toggle.bind(this);

      this.el.addEventListener('click', this.toggle);
      document.addEventListener('click', this.handleDocumentClick);
      document.addEventListener('keydown', this.handleKeydown);
    }

    toggle(event) {
      if (event.target.classList.contains('disabled') || event.target.hasAttribute('disabled')) return;

      const parent = this.getParent(this.el);
      const dropdownMenu = parent.querySelector('ul');

      this.clearMenus();

      const isActive = parent.classList.contains('open');

      if (!isActive) {
        if ('ontouchstart' in document.documentElement) {
          document.body.insertAdjacentHTML('beforeend', '<div class="dropdown-backdrop"></div>');
          document.querySelector('.dropdown-backdrop').addEventListener('click', this.clearMenus);
        }
        parent.classList.toggle('open');
        dropdownMenu.focus();
      }

      dropdownMenu.setAttribute('aria-expanded', String(!isActive));
      dropdownMenu.setAttribute('aria-hidden', String(isActive));
    }

    handleKeydown(event) {
      if (!/(38|40|27)/.test(event.keyCode)) return;

      event.preventDefault();
      event.stopPropagation();

      if (this.el.classList.contains('disabled') || this.el.hasAttribute('disabled')) return;

      const parent = this.getParent(this.el);
      const isActive = parent.classList.contains('open');

      if (!isActive || (isActive && event.keyCode === 27)) {
        if (event.which === 27) parent.querySelector('[data-toggle="dropdown"]').focus();
        return this.toggle(event);
      }

      const items = Array.from(parent.querySelectorAll('[role="menu"] li:not(.divider):visible a'));
      if (!items.length) return;

      let index = items.findIndex(item => item === document.activeElement);

      if (event.keyCode === 38 && index > 0) index--; // up
      if (event.keyCode === 40 && index < items.length - 1) index++; // down
      if (index === -1) index = 0;

      items[index].focus();
    }

    handleDocumentClick() {
      this.clearMenus();
    }

    clearMenus() {
      const backdrop = document.querySelector('.dropdown-backdrop');
      if (backdrop) {
        backdrop.remove();
      }

      Array.from(document.querySelectorAll('[data-toggle="dropdown"]')).forEach(el => {
        this.getParent(el).classList.remove('open');
      });
    }

    getParent(element) {
      const selector = element.getAttribute('data-target') || element.getAttribute('href');
      const parent = selector && document.querySelector(selector);
      return parent || element.parentElement;
    }
  }

  const dropdownElements = document.querySelectorAll('[data-toggle="dropdown"]');
  dropdownElements.forEach(element => {
    const data = element._dropdown;
    if (!data) {
      element._dropdown = new Dropdown(element);
    }
  });
}

bootstrapDropdown();
