/*jshint jquery: true*/

// Skip Nav Link
// clicking or press enter on the skip nav link should bypass site navigation for users of assistive technology
export function skipnav() {
  var skipNavLink = document.querySelector('.skipNavigation'),
    netforumBody = document.querySelector('#BodyTag'),
    cqArticleBody = document.querySelector('.article-body'),
    cqBody = document.querySelector('#bd'),
    skipNavLinkTarget;

  // If the current page is hosted in netforum then we need to work around
  // some bad markup.
  //
  // Effectivley every NetForum site uses the same busted markup
  // this is caused by the premature closing of the #bd
  // and injectection of unessarry tables and other barfed up
  // code that should not exist. We gave up trying to get this fixed
  // now we just except that it is so.
  // hence this function checks for class === 'BodyTag'
  if (!!netforumBody) {
    skipNavLinkTarget = netforumBody;
  }
  // If the current page is a CQ5 article page then the skip nav link
  // should bypass the left navi menu
  else if (!!cqArticleBody) {
    skipNavLinkTarget = cqArticleBody;
  }
  // By default use the body of the page unless there's a left navi
  // menu of netforum page
  else {
    skipNavLinkTarget = cqBody;
  }
  // The clicked anchor reference has an id set to the maincontent
  // however we will also set the focus to the maincontent region as well
  // becuase the AT virtual cursor is not the same the focus
  if (!!skipNavLink && !!skipNavLinkTarget) {
    skipNavLink.addEventListener('click', function (event) {
      event.preventDefault();
      skipNavLinkTarget.setAttribute('tabindex', '-1');
      skipNavLinkTarget.focus();
    });
  }
}
skipnav();
