/*jshint jquery: true, multistr: true*/
/*global acs, _ */
/*
 * LoDash templates for SAYT functionality
 */
export function sayt() {
  // Public
  // NOTE - I tried using various micro templating engines, then ran performance tests - string interpolation is DEFINITELY fastest
  // TODO parameterize / test the different search options (pubs, communities, etc)
  /*    var lwTpl = function(text){
        return function(config){
            return text.replace(/\' + data.query + '/g, config.query).replace;
        };
    };*/
  var _getRootDomain3 = function () {
    if (acs && acs.sso && acs.sso.loginUrl && acs.sso.loginUrl.indexOf('/bin/acs/sso/login') > -1) {
      return acs.sso.loginUrl
        .replace('/bin/acs/sso/login', '')
        .replace('https:', 'http:')
        .replace('http:', window.location.protocol)
        .replace('cmswwwtst', 'cmswwwdev');
    }
    return 'https://www.acs.org';
  };
  var _rootDomain = _getRootDomain3();

  var headerText = '<h4>Did you mean...</h4>';
  var footerTpl = function (data) {
    return (
      '<div class="sayt-other-sites">' +
      '<h4>Search on other ACS Websites....</h4>' +
      '<div><a href="http://pubs.acs.org/action/doSearch?AllField=' +
      data.query +
      '"><strong>' +
      data.query +
      '</strong> in <span>ACS Publications</span></a></div>' +
      '<div><a href="http://communities.acs.org/search.jspa?q=' +
      data.query +
      '"><strong>' +
      data.query +
      '</strong> in <span>ACS Network</span></a></div>' +
      '<div><a href="http://pubs.acs.org/iapps/wld/cen/results.html?line3=' +
      data.query +
      '"><strong>' +
      data.query +
      '</strong> in <span>C&EN</span></a></div>' +
      '</div>'
    );
  };
  var _emptyTpl = function (data) {
    return '<div class="sayt-empty-message"></div>' + footerTpl(data);
  };
  var gsaTemplates = {
    empty: _emptyTpl,
    header: headerText,
    footer: footerTpl,
  };

  var thmbFeaturedTpl = function (data) {
    return (
      '<a href="' +
      _rootDomain +
      data.path +
      '"><div class="tt-curated-img"><img src="' +
      data.thumbnail +
      '"/></div><div class="tt-curated-des"><h4>' +
      data.title +
      '</h4><p>' +
      data.description +
      '</p></div></a>'
    );
  };
  var textFeaturedTpl = function (data) {
    return '<a href="' + _rootDomain + data.path + '"><div><div><div>' + data.title + '</div><div">' + data.description + '</div></div></div></a>';
  };

  var suggestFeaturedTpl = function (data) {
    if (typeof data.thumbnail == 'string') {
      // this is our way of externalizing the link as a temporary fix for GSA hosted SAYT
      data.thumbnail = _rootDomain + data.thumbnail;
      return thmbFeaturedTpl(data);
    } else {
      return textFeaturedTpl(data);
    }
  };

  var featuredTemplates = {
    suggestion: suggestFeaturedTpl,
  };

  if (typeof acs.ui === 'undefined') {
    acs.ui = {};
  }
  Object.assign(acs.ui, {
    sayt: {
      featuredTemplates: featuredTemplates,
      gsaTemplates: gsaTemplates,
    },
  });
}
sayt();
