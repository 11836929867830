import { amplify } from './acs-amplify.js';
var getCuratedSearchPath = function () {
  var rootDomain = 'https://www.acs.org';
  var _acs = window.acs;
  if (_acs && _acs.sso && _acs.sso.loginUrl && _acs.sso.loginUrl.indexOf('/bin/acs/sso/login') > -1) {
    rootDomain = _acs.sso.loginUrl
      .replace('/bin/acs/sso/login', '')
      .replace('https:', 'http:')
      .replace('http:', window.location.protocol)
      .replace('cmswwwtst', 'cmswwwdev');
  }
  return rootDomain + '/etc/designs/acs/curated/acs/_jcr_content.json?cacheBust=' + new Date().getTime();
};

const params = new URLSearchParams(window.location.search);

var getSsoEnv = function () {
  var ssoEnv = 'prod';

  if (window.location.host.indexOf('dev') >= 0) {
    ssoEnv = 'dev';
  }
  if (window.location.host.indexOf('test') >= 0 || window.location.host.indexOf('stg') >= 0) {
    ssoEnv = 'test';
  }

  if (!!window.acs.sso.statusUrl) {
    if (window.acs.sso.statusUrl.indexOf('dev') >= 0) {
      ssoEnv = 'dev';
    }
    if (window.acs.sso.statusUrl.indexOf('test') >= 0) {
      ssoEnv = 'test';
    }
  }

  if (params.get('ssoenv') == 'dev') {
    ssoEnv = 'dev';
  }
  if (params.get('ssoenv') == 'test') {
    ssoEnv = 'test';
  }
  if (params.get('ssoenv') == 'prod') {
    ssoEnv = 'prod';
  }

  return ssoEnv;
};

var getSsoType = function () {
  var ssoType = 'jsonp'; // change to json after switchover

  if (params.get('ssotype') == 'jsonp') {
    ssoType = 'jsonp';
  }
  if (params.get('ssotype') == 'json') {
    ssoType = 'json';
  }

  return ssoType;
};

var getSsoStatusUrl = function () {
  var ssoEndpoint = (getSsoType() == 'json') ? 'idpservice' : 'idp';
  var ssoUrl = 'https://sso' + ((getSsoEnv() == 'prod') ? '' : getSsoEnv()) + '.acs.org/' + ssoEndpoint + '/status';
  return ssoUrl;
};

if (!!window.acs.sso.statusUrl) {
  window.acs.sso.statusUrl = getSsoStatusUrl();
}

var acsStatusConfig = {
  url: getSsoStatusUrl(),
  data: {
    appid: 'WWWACS',
  },
  dataType: getSsoType(),
  jsonp: 'padding',
  cache: 'longpoll',
  topic: 'sso.status',
};

debugit('SSOSWITCH: setting up ' + getSsoType() + ' request at ' + getSsoStatusUrl() + ' in ' + getSsoEnv());

export function requests() {
  amplify.request.define('sso.status', 'ajax', acsStatusConfig);
  amplify.request.define('sso.status.noCache', 'ajax', acsStatusConfig); // not doing the polling thing right now

  amplify.request.define('cq5.whitelist', 'ajax', {
    url:
      acs && acs.page && acs.page.path
        ? acs.page.path
        : window && window.location && window.location.pathname
          ? window.location.pathname
          : '/' + '.whitelist.json?cacheBust=' + new Date().getTime(),
    dataType: 'json',
    type: 'GET',
    cache: true,
  });
  amplify.request.define('acs.search.curatedResult', 'ajax', {
    url: getCuratedSearchPath(),
    dataType: 'json',
    type: 'GET',
    cache: {
      type: 'persist',
      expires: 86400000, // one day expiration
    },
  });
}
requests();
